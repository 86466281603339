<template>
  <div class="media-container">
    <button class="back-button" @click="goBack">Back</button>

    <!-- Show GIF -->
    <template v-if="showGif">
      <img :src="gifSource" @load="startGifPlayback" ref="gif" class="full-gif" />
    </template>

    <!-- Show First Video -->
    <template v-else-if="showFirstVideo">
      <video
        :src="videoSource"
        autoplay
        playsinline
        class="full-video"
        ref="firstVideo"
        @canplay="enableVideoAudio"
        @ended="playSecondVideo"
      ></video>
    </template>

    <!-- Show Second Video -->
    <template v-else-if="showSecondVideo">
      <video
        :src="secondVideoSource"
        autoplay
        playsinline
        class="full-video"
        ref="secondVideo"
        @canplay="enableVideoAudio"
        @ended="playBackroomGif"
      ></video>
    </template>

    <!-- Show Backroom GIF -->
    <template v-else-if="showBackroomGif">
      <img :src="backroomGifSource" ref="backroomGif" class="full-gif" @load="playBackroomAudio" />
    </template>

    <!-- Show Hallway Video -->
    <template v-else-if="showHallwayVideo">
      <video
        :src="hallwayVideoSource"
        autoplay
        playsinline
        class="full-video"
        ref="hallwayVideo"
        @ended="showOfficeScene"
      ></video>
    </template>

    <!-- Show Office Scene with Popup -->
    <template v-else-if="showOffice">
      <img :src="officeImageSource" class="full-image" />
      <div class="popup">
        <h2>Recommended Dossiers</h2>
        <ul>
          <li>Maxwell 1</li>
          <li>Bill Gates Mansion and Child P*rn?</li>
          <li>Balenciaga: Controversy</li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'GifAndVideoView',
  data() {
    return {
      gifSource: require('@/assets/images/trolley.gif'),
      gifDuration: 5000,
      videoSource: require('@/assets/video/trolleyride.mp4'),
      secondVideoSource: require('@/assets/video/fastforward.mp4'),
      backroomGifSource: require('@/assets/images/backroom.gif'),
      hallwayVideoSource: require('@/assets/video/hallway.mp4'),
      officeImageSource: require('@/assets/images/office.webp'),
      audioSource: require('@/assets/audio/Foul14.mp3'),
      backroomAudioSource: require('@/assets/audio/backroom.mp3'),
      showGif: true,
      showFirstVideo: false,
      showSecondVideo: false,
      showBackroomGif: false,
      showHallwayVideo: false,
      showOffice: false,
      audio: null,
      backroomAudio: null
    };
  },
  methods: {
    startGifPlayback() {
      this.audio = new Audio(this.audioSource);
      this.audio.loop = true;
      this.audio.play().catch((error) => console.error('Error playing audio:', error));

      setTimeout(() => {
        this.showGif = false;
        this.showFirstVideo = true;
      }, this.gifDuration);
    },
    enableVideoAudio() {
      const videoElement = this.$refs.firstVideo || this.$refs.secondVideo || this.$refs.hallwayVideo;
      if (videoElement) {
        videoElement.muted = false;
        videoElement.volume = 1.0;
      }
    },
    playSecondVideo() {
      this.showFirstVideo = false;
      this.showSecondVideo = true;
    },
    playBackroomGif() {
      this.showSecondVideo = false;
      this.showBackroomGif = true;
    },
    playBackroomAudio() {
      this.backroomAudio = new Audio(this.backroomAudioSource);
      this.backroomAudio.play().catch((error) => console.error('Error playing backroom audio:', error));

      // Fade out `Foul14` audio
      const fadeOutInterval = setInterval(() => {
        if (this.audio && this.audio.volume > 0.1) {
          this.audio.volume -= 0.1;
        } else {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          clearInterval(fadeOutInterval);
        }
      }, 200);

      this.backroomAudio.onended = () => {
        this.showBackroomGif = false;
        this.showHallwayVideo = true; // Transition to hallway video
      };
    },
    showOfficeScene() {
      this.showHallwayVideo = false;
      this.showOffice = true; // Transition to office scene
    },
    goBack() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      if (this.backroomAudio) {
        this.backroomAudio.pause();
        this.backroomAudio.currentTime = 0;
      }
      this.$router.push('/home');
    }
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    if (this.backroomAudio) {
      this.backroomAudio.pause();
      this.backroomAudio.currentTime = 0;
    }
  }
};
</script>

<style scoped>
/* Media Container and General Setup */
.media-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black; /* Optional for transition clarity */
}

.full-gif,
.full-video,
.full-image {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures proper scaling for all media */
}

/* Back Button */
.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 100;
  background-color: black;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Popup Styling */
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(34, 34, 34, 0.9); /* Dark translucent background */
  color: white;
  padding: 25px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Shadow for depth */
  animation: fade-in 1s ease-in-out; /* Add smooth fade-in animation */
}

.popup h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  text-transform: uppercase;
  border-bottom: 2px solid #f4f4f4;
  padding-bottom: 10px;
}

.popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup li {
  font-size: 1.4rem;
  margin: 15px 0;
  color: #f4f4f4;
}

.popup li:hover {
  color: #cccccc; /* Slight hover effect for items */
}

/* Fade-in Animation for Popup */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Additional Media Queries (Optional) */
@media (max-width: 768px) {
  .popup {
    width: 95%;
    font-size: 0.9rem;
  }

  .popup h2 {
    font-size: 1.5rem;
  }

  .popup li {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .popup {
    width: 90%;
    padding: 15px;
  }

  .popup h2 {
    font-size: 1.3rem;
  }

  .popup li {
    font-size: 1rem;
  }
}
</style>
