<template>
  <div class="gif-container">
    <img :src="gifSource" @load="startGifPlayback" ref="gif" class="full-gif" />
  </div>
</template>

<script>
export default {
  name: 'GifView',
  data () {
    return {
      gifSource: require('@/assets/images/rabbit.gif'), // Path to your GIF
      gifDuration: 5000 // Duration in ms (5 seconds as an example)
    }
  },
  methods: {
    startGifPlayback () {
      setTimeout(() => {
        this.$router.push('/disclaimer') // Navigate to the next view
      }, this.gifDuration)
    }
  }
}
</script>

<style scoped>
.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black; /* Optional, adjust as needed */
}

.full-gif {
  height: 100%;
  width: auto;
}
</style>
