<template>
  <div class="top-bar">
    <!-- Left section: Dossier Name, Question Number, and Time Left -->
    <!-- Account Section with Sense Display -->
    <div class="account-section" @click="toggleAccountDropdown">
      <i class="fa fa-user-circle account-icon"></i>
      <span class="user-name">{{ userName }}&nbsp;</span>
      <span class="sense-balance"> Cents: {{ userSense }}</span>
    </div>
    <div class="info-section">
      <span class="info-item">Sense Gained/Lost: {{ totalSensePoints }}</span>
      <span class="pipe-divider">|</span>
      <span class="info-item">Question {{ currentQuestionIndex + 1 }} of {{ questions.length }}</span>
      <span class="pipe-divider">|</span>
      <div v-if="timerVisible && timer > 0">
      <span class="info-item">Time Left: {{ timer }}s</span>
        </div>
    </div>
    <!-- Paperclip Icon for Save Options (now on the top right) -->
    <i class="fas fa-paperclip paperclip-icon" @click="showSaveModal = true"> {{ paperclips }} </i>
    <div v-if="dropdownOpen" class="dropdown-menu">
      <button class="dropdown-item" @click="goBack">Back</button>
      <button class="dropdown-item" @click="handleBuySense">Buy Cents</button>
      <button class="dropdown-item" @click="handleLogout">Logout</button>
    </div>
  </div>
  <div class="questions-container">
    <!-- Back Button -->
    <img src="@/assets/images/redacted.webp" class="redacted-image" alt="Redacted Background" />
    <!-- Account Section with Sense Display -->
    <!-- Display questions during the quiz -->
    <div class="question-wrapper" v-if="!quizCompleted && questions.length > 0">
      <div class="debug-controls" v-if="debugMode">
        <button class="btn-debug-skip" @click="skipQuestion">Skip Question</button>
        <button class="btn-debug-complete" @click="completeQuiz">Complete Quiz</button>
        <button class="btn-debug-sense" @click="addSensePoints">Add 500 Sense</button>
      </div>
      <!-- Paperclip Icon for Save Options (Positioned within the white question container) -->
      <div class="timer" v-if="timerVisible && timer > 0">
<!--        <p>Time Left: {{ timer }}s</p>-->
      </div>
      <div class="sense-paperclip-display">
        <i class="fas fa-paperclip paperclip-icon" @click="showSaveModal = true"> {{ paperclips }} </i>
      </div>
      <div class="question-content">
        <button class="back-button" @click="goBack">Back</button>
        <span class="info-item">{{ dossierName }}</span>
        <p class="question">{{ currentQuestion.question }}</p>
      </div>
      <div class="answers">
        <button
          v-for="(answer, index) in processedAnswers"
          :key="index"
          :class="[
      'answer-button',
      feedbackState && String(answer).trim().toLowerCase() === String(currentQuestion.correctAnswer).trim().toLowerCase()
        ? 'correct'
        : feedbackState === 'incorrect' && String(selectedAnswer).trim() === String(answer).trim()
        ? 'incorrect'
        : ''
    ]"
          @click="checkAnswer(answer)"
          :disabled="feedbackState !== null"
        >
          {{ String(answer) }}
        </button>
      </div>
      <!-- Timer display -->

    </div>

    <div
      class="sense-animation"
      v-if="feedbackAnimation && !showCompletedMessageModal"
      :class="{ 'positive': feedbackState === 'correct', 'negative': feedbackState === 'incorrect' }"
      >
      <span>{{ feedbackState === 'correct' ? '+3' : '-2' }} Sense</span>
    </div>
    <!-- Display results after the quiz is completed -->
    <div class="results-wrapper" v-if="quizCompleted">
      <h1 class="results-title">Quiz Results</h1>
      <p class="results-summary">You got {{ correctAnswers }} out of {{ questions.length }} correct!</p>

      <div class="results-buttons">
        <button class="btn-outline" @click="showSaveModal = true">Save Dossier (10 Sense)</button>
        <button class="btn-outline" @click="moveToNextDossier">Next Dossier</button>
        <button class="btn-outline" @click="goToHomePage">Categories</button>
      </div>
    </div>

    <!-- Save Options Modal -->
    <div class="save-modal" v-if="showSaveModal">
      <div class="save-modal-content">
        <h2>Save Options</h2>
        <button class="btn-save" @click="saveCurrentQuestion">Save Question (1 Paperclip)</button>
        <button class="btn-cancel" @click="showSaveModal = false">Cancel</button>
      </div>
    </div>

    <!-- Timer Expired Animation -->
    <div class="timer-expired-animation" v-if="timerExpired">
      <span>Time's up!</span>
    </div>
  </div>

  <!-- Completed Message Modal -->
  <div class="completed-message-modal" v-if="showCompletedMessageModal">
    <div class="completed-message-content">
      <h2>This quiz has already been completed</h2>
      <p>Your last score: {{ lastCompletedScore }} ({{ lastAttempts }} attempts)</p>
      <p>Points will neither be deducted nor added to your total sense for this attempt.</p>
      <button @click="closeCompletedMessageModal" class="btn-continue">Continue</button>
    </div>
  </div>
  <!-- Modal for Next Dossier Navigation -->
  <div v-if="showPurchaseModal" class="modal show d-block" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Purchase Dossier</h5>
          <div class="modal-body">
            <p>{{ nextDossier }}</p>
          </div>
          <button type="button" class="btn-close" @click="cancelPurchase"></button>
        </div>
        <div class="modal-body">
          <p>{{ purchaseMessage }}</p>
        </div>
        <div v-if="!purchaseConfirmed" class="modal-footer">
          <button @click="confirmPurchase" class="btn btn-primary">Yes</button>
          <button @click="cancelPurchase" class="btn btn-secondary">No</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Confirmation Modal for Next Dossier (If Already Purchased) -->
  <div v-if="showNextDossierModal" class="modal show d-block" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Move to Next Dossier</h5>
          <button type="button" class="btn-close" @click="cancelNextDossier"></button>
        </div>
        <div class="modal-body">
          <p>Would you like to move on to {{ nextDossier }}?</p>
        </div>
        <div class="modal-footer">
          <button @click="moveToConfirmedNextDossier" class="btn btn-primary">Yes</button>
          <button @click="cancelNextDossier" class="btn btn-secondary">No</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Paperclip Save Modal (for both questions and dossiers) -->
  <div class="save-modal" v-if="showSaveModal">
    <div class="save-modal-content">
      <h2>{{ quizCompleted ? 'Save Dossier' : 'Save Question' }}</h2>
      <p>You have {{paperclips}} paperclip(s)!</p>
      <p v-if="paperclips > 0">
        Use 1 paperclip to {{ quizCompleted ? 'save your dossier' : 'save this question' }}.
      </p>
      <p v-else>
        Use 10 Cents to {{ quizCompleted ? 'save your dossier' : 'save this question' }}.
      </p>
      <button class="btn-save" @click="handleSave" v-if="paperclips > 0">Use Paperclip</button>
      <button class="btn-save" @click="handleSave(true)" v-if="paperclips === 0 && sense >= 10">Use 10 Cents</button>
      <button class="btn-cancel" @click="showSaveModal = false">Cancel</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {getFirestore, collection, getDocs, addDoc, doc, getDoc, updateDoc} from "firebase/firestore"; // Import Firestore functions
import { getAuth } from "firebase/auth"; // For user authentication
import { mapActions, mapGetters } from 'vuex'; // Import Vuex mapActions and mapGetters

export default {
  name: 'QuestionsView',
  props: {
    categoryId: {
      type: String,
      required: true
    },
    dossierId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      questions: [],
      currentQuestionIndex: 0,
      correctAnswers: 0,
      quizCompleted: false,
      feedbackState: null,
      userSense: 0,
      totalSensePoints: 0,
      feedbackAnimation: false,
      sensePointsChange: 0,
      selectedAnswer: null,
      showSaveModal: false,
      timer: 30,
      timerVisible: true,
      timerExpired: false,
      timerInterval: null,
      componentActive: true, // Track component's active state
      isNextDossierPurchased: false,
      showDossierModal: false,
      showCompletedMessageModal: false,
      lastCompletedScore: null, // For showing last completed score
      lastAttempts: null,
      isLoading: true,  // Added to control loading state
      hasModalBeenShown: false,  // Tracks if modal has already been shown
      isInProgressMode: false,
      isReviewMode: false, // New flag to indicate review mode
      showPurchaseModal: false,
      showNextDossierModal: false,
      purchaseMessage: '',
      purchaseConfirmed: false,
      dossierToPurchase: null,
      nextDossier: '',
      nextDossierCategory: '',
      debugMode: false,
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['sense', 'paperclips']),
    totalSense() {
      return this.sense || 0;
    },
    dossierName() {
      // Assuming `dossierId` or other identifiers can help retrieve the dossier name
      return this.$route.params.dossierId || 'Dossier';
    },
    userName() {
      return this.$store.getters['auth/agentName'] || 'Agent';
    },
    userSense() {
      return this.$store.getters['auth/sense'] || 0;
    },
    currentQuestion() {
      return this.questions[this.currentQuestionIndex] || {};
    },
    processedAnswers() {
      const validAnswers = this.currentQuestion.answers
        ? this.currentQuestion.answers
          .filter(answer => answer !== 'N/A')
          .map(answer => String(answer).trim()) // Trim any extra whitespace
        : [];

      const specialAnswers = validAnswers.filter(answer => /all|none/i.test(answer));
      const regularAnswers = validAnswers.filter(answer => !/all|none/i.test(answer));

      // Shuffle the entire set of regular answers
      const randomAnswers = this.shuffleArray(regularAnswers);

      // Combine shuffled regular answers with special answers
      return [...randomAnswers, ...specialAnswers];
    }
  },
  watch: {
    '$route.params.dossierId': {
      immediate: true,
      handler() {
        this.resetQuizState();  // Reset quiz state when route changes
        this.fetchQuestions();  // Fetch new questions
      }
    }
  },

  methods: {
    ...mapActions('auth', ['usePaperclip', 'updateSensePoints', 'markDossierCompleted', 'saveDossierProgress']),
    addSensePoints() {
      this.$store.dispatch('auth/updateSensePoints', 500);
      alert('500 sense points added!');
    },
    handleLogout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    handleBuySense() {
      if (!this.quizCompleted) {
        this.saveProgress();
      }
      this.$router.push('/paypal');
    },
    toggleAccountDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    async handleSave(useSense = false) {
      const db = getFirestore();
      const userRef = doc(db, 'users', this.$store.getters['auth/user'].uid);

      // Debugging: Log Firestore reference and user UID
      console.log('Firestore userRef:', userRef);
      console.log('User UID:', this.$store.getters['auth/user'].uid);

      try {
        const userDoc = await getDoc(userRef);
        console.log('User document data BEFORE update:', userDoc.data());

        const paperclips = this.$store.getters['auth/paperclips'];
        const sense = this.$store.getters['auth/sense'];

        if (paperclips > 0) {
          // Deduct a paperclip if available
          const newPaperclips = paperclips - 1;
          console.log('New paperclips after deduction:', newPaperclips);

          // Update Firestore first
          await updateDoc(userRef, { paperclips: newPaperclips });
          console.log('Paperclips successfully updated in Firestore.');

          // Fetch the document again to verify the update
          const updatedUserDoc = await getDoc(userRef);
          console.log('User document data AFTER paperclip update:', updatedUserDoc.data());

          // Now update Vuex state
          this.$store.commit('auth/SET_PAPERCLIPS', newPaperclips);

        } else if (sense >= 10) {
          // Deduct sense points if no paperclips available
          const newSense = sense - 10;
          console.log('New sense after deduction:', newSense);

          // Update Firestore first
          await updateDoc(userRef, { sense: newSense });
          console.log('Sense successfully updated in Firestore.');

          // Fetch the document again to verify the update
          const updatedUserDoc = await getDoc(userRef);
          console.log('User document data AFTER sense update:', updatedUserDoc.data());

          // Now update Vuex state
          this.$store.commit('auth/SET_SENSE', newSense);

        } else {
          // Not enough paperclips or sense, redirect to PayPal
          console.error('Not enough paperclips or sense points to complete the action. Redirecting to PayPal.');
          this.$router.push({ name: 'paypal' });
          return;
        }

        // Determine whether to save the question or the dossier
        if (this.quizCompleted) {
          await this.saveDossier();  // Save the entire dossier if quiz is completed
        } else {
          await this.saveCurrentQuestion();  // Save the current question
        }

        // If everything is successful, hide the save modal
        console.log('Save process completed successfully.');
        this.showSaveModal = false;

      } catch (error) {
        console.error('Error saving:', error);
        alert('Error occurred during save.');
      }
    },

    async saveCurrentQuestion() {
      // Log to check method entry
      console.log('Entered saveCurrentQuestion method.');

      const currentQuestion = this.questions[this.currentQuestionIndex];
      const userId = this.$store.getters['auth/user']?.uid;

      // Log current question and userId
      console.log('Current question:', currentQuestion);
      console.log('User ID:', userId);

      if (userId) {
        try {
          const questionData = {
            userId,
            question: currentQuestion.question,
            correctAnswer: currentQuestion.correctAnswer,
            answers: currentQuestion.answers,
            timestamp: new Date(),
          };

          // Log the question data to be saved
          console.log('Question data to be saved:', questionData);

          await addDoc(collection(getFirestore(), 'savedQuestions'), questionData);

          // Log success message
          console.log('Question successfully saved to Firestore.');
          alert('The question has been saved!');
        } catch (error) {
          // Log any error that occurs
          console.error('Error saving question:', error);
        }
      } else {
        // Log the case where userId is not available
        console.error('User ID is missing. Question save aborted.');
      }
    },

    async saveDossier() {
      // Log to check method entry
      console.log('Entered saveDossier method.');

      const userId = this.$store.getters['auth/user']?.uid;

      // Log userId and dossier details
      console.log('User ID:', userId);
      console.log('Dossier ID:', this.$route.params.dossierId);
      console.log('Correct Answers:', this.correctAnswers);
      console.log('Total Questions:', this.questions.length);

      if (userId) {
        try {
          const dossierData = {
            userId,
            dossierId: this.$route.params.dossierId,
            correctAnswers: this.correctAnswers,
            totalQuestions: this.questions.length,
            timestamp: new Date(),
          };

          // Log the dossier data to be saved
          console.log('Dossier data to be saved:', dossierData);

          await addDoc(collection(getFirestore(), 'savedDossiers'), dossierData);

          // Log success message
          console.log('Dossier successfully saved to Firestore.');
          alert('Your dossier has been saved!');
        } catch (error) {
          // Log any error that occurs
          console.error('Error saving dossier:', error);
        }
      } else {
        // Log the case where userId is not available
        console.error('User ID is missing. Dossier save aborted.');
      }
    },

    async getNextDossier() {
      console.log("Fetching next dossier...");
      const db = getFirestore();
      const categoryId = this.$route.params.categoryId;
      const dossierId = this.$route.params.dossierId;

      try {
        const categoryRef = doc(db, 'questions', categoryId);
        const categoryDoc = await getDoc(categoryRef);

        if (categoryDoc.exists()) {
          const dossiersInfo = categoryDoc.data().dossiersInfo;
          console.log("Dossiers Info:", dossiersInfo);

          if (dossiersInfo && dossiersInfo[dossierId]) {
            const nextDossier = dossiersInfo[dossierId].nextDossier;
            const nextDossierCategory = dossiersInfo[dossierId].nextDossierCategory;

            if (nextDossier && nextDossierCategory) {
              console.log("Next dossier found:", nextDossier, nextDossierCategory);
              this.nextDossier = nextDossier;  // Set the next dossier
              this.nextDossierCategory = nextDossierCategory;
              this.checkNextDossier(nextDossierCategory, nextDossier);
            } else {
              console.warn("No next dossier available, redirecting to home.");
              this.goToHomePage(); // If no next dossier, go to home
            }
          } else {
            console.warn("No dossiers info found for this category.");
            this.goToHomePage();
          }
        } else {
          console.error("Category document not found.");
          this.goToHomePage();
        }
      } catch (error) {
        console.error("Error fetching next dossier info:", error);
        this.goToHomePage();
      }
    },

    async checkNextDossier(categoryId, dossierId) {
      console.log("Checking if dossier exists in category...");

      const db = getFirestore();
      const categoryRef = doc(db, 'questions', categoryId); // Get the category document
      console.log(`Checking category at path: questions/${categoryId}`);

      try {
        const categoryDoc = await getDoc(categoryRef); // Fetch the category document
        console.log('Fetched category document:', categoryDoc);

        // Check if the category document exists
        if (!categoryDoc.exists()) {
          console.warn("Category does not exist in the database.");
          alert('This category does not exist. Please contact support or try again later.');
          this.goToHomePage(); // Redirect the user to the homepage or handle appropriately
          return;
        }

        // Extract the dossiers array from the category document
        const dossiers = categoryDoc.data().dossiers || [];
        console.log('Dossiers found in category:', dossiers);

        // Check if the requested dossier exists in the dossiers array
        if (!dossiers.includes(dossierId)) {
          console.warn("Dossier does not exist in the selected category.");
          alert('This dossier does not exist. Please contact support or try again later.');
          this.goToHomePage(); // Redirect the user to the homepage or handle appropriately
          return;
        }

        // If the dossier exists, continue with the purchase check
        const purchasedDossiers = this.$store.getters['auth/purchasedDossiers'];
        console.log("Purchased Dossiers:", purchasedDossiers);

        const isPurchased = purchasedDossiers.some(
          item => item.category === categoryId && item.dossier === dossierId
        );

        if (!isPurchased) {
          console.log("Dossier not purchased, prompting for purchase.");
          this.promptPurchase(categoryId, dossierId); // Trigger purchase prompt
        } else {
          console.log("Dossier is purchased, prompting to move to next dossier.");
          this.promptNextDossier();  // Show next dossier confirmation modal
        }
      } catch (error) {
        console.error("Error checking dossier existence:", error);
        alert('An error occurred while checking dossier existence. Please try again later.');
        this.goToHomePage(); // Redirect to the homepage or handle as needed
      }
    },

    // Show the next dossier confirmation modal
    promptNextDossier() {
      console.log("Showing next dossier modal...");
      this.showNextDossierModal = true; // Make sure this is properly set
    },
    // Confirm and move to the next dossier
    moveToConfirmedNextDossier() {
      console.log("Moving to confirmed next dossier:", this.nextDossierCategory, this.nextDossier);
      this.resetQuizState();  // Reset the quiz state before navigating
      this.$router.push({ name: 'QuestionsView', params: { categoryId: this.nextDossierCategory, dossierId: this.nextDossier } });
      this.showNextDossierModal = false;
    },

    moveToNextDossier() {
      console.log("Next dossier button clicked, fetching next dossier...");
      this.getNextDossier(); // Trigger the next dossier fetch process
    },

    // Method to prompt the purchase if necessary
    promptPurchase(categoryId, dossier) {
      this.dossierToPurchase = { category: categoryId, dossier };
      this.purchaseMessage = 'This Dossier has not yet been unlocked. Would you like to purchase it now for 100 sense?';
      this.purchaseConfirmed = false;
      this.showPurchaseModal = true;
    },

    resetQuizState() {
      this.quizCompleted = false;
      this.currentQuestionIndex = 0;
      this.correctAnswers = 0;
      this.feedbackState = null;
      this.selectedAnswer = null;
      this.feedbackAnimation = false;
      this.sensePointsChange = 0;
      this.timerVisible = true;
      this.timerExpired = false;
      this.hasModalBeenShown = false;
      this.isInProgressMode = false;
      this.isReviewMode = false;
      this.questions = [];  // Clear questions
    },

    async confirmPurchase() {
      const { category, dossier } = this.dossierToPurchase;

      if (this.sense >= 100) {
        // Deduct 100 sense points from the user's account
        await this.$store.dispatch('auth/updateSensePoints', -100);

        // Fetch the updated purchased dossiers from Vuex
        const updatedPurchasedDossiers = [...this.$store.getters['auth/purchasedDossiers'], { category, dossier }];

        // Update the user's purchased dossiers in Firestore
        await updateDoc(doc(getFirestore(), 'users', this.$store.getters['auth/user'].uid), {
          purchasedDossiers: updatedPurchasedDossiers,
        });

        // Update the purchased dossiers in Vuex
        this.$store.commit('auth/SET_PURCHASED_DOSSIERS', updatedPurchasedDossiers);

        // Show purchase success message
        this.purchaseMessage = 'Purchase successful!';
        this.purchaseConfirmed = true;

        // Ensure the modal closes
        setTimeout(() => {
          this.showPurchaseModal = false;
          this.purchaseMessage = '';
          this.dossierToPurchase = null;
        }, 1500);

        // Automatically navigate to QuestionsView after purchase
        console.log("Redirecting to QuestionsView:", category, dossier);
        this.$router.push({ name: 'QuestionsView', params: { categoryId: category, dossierId: dossier } });

      } else {
        // Show error if not enough sense points
        this.purchaseMessage = 'Not enough sense points. Please purchase more.';
        this.purchaseConfirmed = false;
        this.$router.push({ name: 'paypal' });
      }
    },


    cancelNextDossier() {
      this.showNextDossierModal = false;
    },

    cancelPurchase() {
      this.showPurchaseModal = false;
      this.purchaseMessage = '';
    },

    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    skipQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
      } else {
        this.completeQuiz(); // Automatically complete the quiz if it's the last question
      }
    },

    // Method to immediately complete the quiz
    completeQuiz() {
      this.quizCompleted = true;
      this.pushTotalSensePoints(); // Final sync after quiz completion
    },

    async fetchQuestions() {
      if (!this.componentActive) return;
      const categoryId = this.$route.params.categoryId;
      const dossierId = this.$route.params.dossierId;

      // Fetch completedDossiers from Vuex
      const completedDossiers = [...(this.$store.getters['auth/completedDossiers'] || [])];

      // Find completed dossier
      const completedDossier = completedDossiers.find(
        (dossier) => dossier.category === categoryId && dossier.dossier === dossierId
      );

      if (completedDossier && !this.hasModalBeenShown) {
        this.lastCompletedScore = completedDossier.finalScore;
        this.lastAttempts = completedDossier.attempts;
        this.showCompletedMessageModal = true;
        this.isReviewMode = true; // Set review mode for reattempts
        this.isLoading = false;
        return;
      }

      // Fetch questions normally
      const db = getFirestore();
      const questionsRef = collection(db, 'questions', categoryId, dossierId);
      const questionsSnapshot = await getDocs(questionsRef);
      this.questions = questionsSnapshot.docs.map((doc) => doc.data()).sort((a, b) => a.number - b.number);

      this.userSense = this.totalSense;
      this.startTimer();
      this.isLoading = false;
    },

    checkAnswer(selectedAnswer) {
      if (!this.componentActive) return;
      clearInterval(this.timerInterval);
      this.timerVisible = false;
      this.selectedAnswer = String(selectedAnswer).trim();

      const correctAnswer = this.currentQuestion.correctAnswer
        ? String(this.currentQuestion.correctAnswer).trim()
        : '';

      // Check if the selected answer is correct
      if (this.selectedAnswer.toLowerCase() === correctAnswer.toLowerCase()) {
        this.correctAnswers++;
        this.feedbackState = 'correct';

        // Only play animation and update sense points if NOT in review mode
        if (!this.isReviewMode) {
          this.sensePointsChange = 3;
          this.totalSensePoints += 3;
          this.feedbackAnimation = true;
        }
      } else {
        this.feedbackState = 'incorrect';

        if (!this.isReviewMode) {
          this.sensePointsChange = -2;
          this.totalSensePoints -= 2;
          this.feedbackAnimation = true;
        }
      }

      // Ensure feedbackAnimation hides after 1 second even in review mode
      setTimeout(() => {
        this.feedbackAnimation = false;
      }, 1000);
      // Set a flag for immediate navigation

      // Clear feedback state and move to next question
      setTimeout(() => {
        this.feedbackState = null;
        this.selectedAnswer = null;
        this.moveToNextQuestion();
      }, 3000);
    },

    startTimer() {
      if (!this.componentActive) return;

      this.clearTimer(); // Clear any existing timer
      this.timer = 45;
      this.timerVisible = true;
      this.timerExpired = false;
      this.timerInterval = setInterval(() => {
        if (!this.componentActive) {
          clearInterval(this.timerInterval);
          return;
        }

        if (this.timer > 0) {
          this.timer--;
        } else {
          this.timerExpired = true;
          clearInterval(this.timerInterval);
          this.markAnswerIncorrect();
        }
      }, 1000);
    },

    clearTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },

    markAnswerIncorrect() {
      if (!this.componentActive) return;
      this.timerVisible = false;
      this.feedbackState = 'incorrect';
      this.sensePointsChange = -2;
      this.totalSensePoints -= 2;

      this.feedbackAnimation = true;
      setTimeout(() => {
        this.feedbackAnimation = false;
      }, 4000);

      setTimeout(() => {
        if (this.componentActive) {
          this.feedbackState = null;
          this.selectedAnswer = null;
          this.moveToNextQuestion();
        }
      }, 4000);
    },

    moveToNextQuestion() {
      if (!this.componentActive) return;

      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.startTimer();
      } else {
        this.quizCompleted = true;
        this.pushTotalSensePoints();
      }
    },

    async pushTotalSensePoints() {
      if (!this.componentActive) return;
      const categoryId = this.$route.params.categoryId;
      const dossierId = this.$route.params.dossierId;

      try {
        // Update the user's total sense points
        await this.updateSensePoints(this.totalSensePoints);

        // Move dossier from in-progress to completed
        await this.markDossierCompleted({
          categoryId: categoryId,
          dossierId: dossierId,
          correctAnswers: this.correctAnswers,
          totalQuestions: this.questions.length,
        });

        // Remove the completed dossier from in-progress in both Vuex and Firestore
        const updatedInProgressDossiers = this.$store.getters['auth/inProgressDossiers'].filter(
          dossier => dossier.category !== categoryId || dossier.dossier !== dossierId
        );
        this.$store.commit('auth/SET_IN_PROGRESS_DOSSIERS', updatedInProgressDossiers);
        await updateDoc(doc(getFirestore(), 'users', this.$store.getters['auth/user'].uid), {
          inProgressDossiers: updatedInProgressDossiers
        });

        //await this.prepareNextDossier();
      } catch (error) {
        console.error("Error in completing dossier:", error);
      }
    },


    closeCompletedMessageModal() {
      if (!this.componentActive) return;
      this.showCompletedMessageModal = false;
      this.hasModalBeenShown = true;
      this.isLoading = true;

      if (this.questions.length === 0) {
        this.fetchQuestions();
      } else {
        this.isLoading = false;
      }
    },



    saveResults() {
      if (!this.componentActive) return;

      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const resultData = {
          userId: user.uid,
          dossierId: this.$route.params.dossierId,
          correctAnswers: this.correctAnswers,
          totalQuestions: this.questions.length,
          timestamp: new Date()
        };

        addDoc(collection(db, 'quizResults'), resultData)
          .then(() => {
            alert('Your results have been saved!');
          })
          .catch(error => {
            console.error('Error saving results: ', error);
            alert('Failed to save results.');
          });
      } else {
        alert('You need to be logged in to save your results.');
      }
    },
    async saveProgress() {
      if (this.quizCompleted) return;

      const db = getFirestore();
      const userRef = doc(db, 'users', this.$store.getters['auth/user']?.uid);
      const categoryId = this.$route.params.categoryId;
      const dossierId = this.$route.params.dossierId;
      const progress = this.currentQuestionIndex;

      // Update in-progress dossier in Vuex
      const updatedInProgressDossiers = [
        ...this.$store.getters['auth/inProgressDossiers'].filter(
          dossier => dossier.category !== categoryId || dossier.dossier !== dossierId
        ),
        {
          category: categoryId,
          dossier: dossierId,
          progress,
          senseChange: this.totalSensePoints  // Save running total of sense points
        }
      ];
      this.$store.commit('auth/SET_IN_PROGRESS_DOSSIERS', updatedInProgressDossiers);

      // Sync in-progress data to Firestore
      await updateDoc(userRef, { inProgressDossiers: updatedInProgressDossiers });
    },
    async goToHomePage() {
      this.$router.push('/desktop');
    },
    async goBack() {
      // If quiz is incomplete, save in-progress data
      if (!this.quizCompleted) {
        await this.saveProgress();
      }
      this.$router.push('/desktop');
    },

  },
  async mounted() {
    this.resetQuizState();
    if (!this.componentActive) return;
    const db = getFirestore();
    const userRef = doc(db, 'users', this.$store.getters['auth/user']?.uid);
    const categoryId = this.$route.params.categoryId;
    const dossierId = this.$route.params.dossierId;

    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Set completed dossiers in Vuex
        this.$store.commit('auth/SET_COMPLETED_DOSSIERS', userData.completedDossiers || []);
        console.log("Completed dossiers set in Vuex:", this.$store.getters['auth/completedDossiers']);

        // Set in-progress dossiers in Vuex if not already set
        if (userData.inProgressDossiers) {
          this.$store.commit('auth/SET_IN_PROGRESS_DOSSIERS', userData.inProgressDossiers);
        }

        // Check for an in-progress entry for this dossier and resume from where left off
        const inProgressDossier = this.$store.getters['auth/inProgressDossiers'].find(
          dossier => dossier.category === categoryId && dossier.dossier === dossierId
        );

        if (inProgressDossier) {
          this.isInProgressMode = true;
          this.currentQuestionIndex = inProgressDossier.progress;
          this.totalSensePoints = inProgressDossier.senseChange || 0;  // Restore sense progress
        }

        if (userData.paperclips !== undefined) {
          this.$store.commit('auth/SET_PAPERCLIPS', userData.paperclips);
          console.log("Paperclips set in Vuex:", userData.paperclips);
        }
      } else {
        console.warn("No user document found in Firestore.");
      }
    } catch (error) {
      console.error("Error fetching user document:", error);
    }

    // Fetch questions and initialize the quiz logic
    await this.fetchQuestions();
    this.startTimer();
  },
  beforeDestroy() {
    this.componentActive = false;
    this.clearTimer();
  },
  beforeRouteLeave(to, from, next) {
    this.componentActive = false;
    this.clearTimer();
    next();
  },
};


</script>

<style scoped>
/* Back button fixed at the top-left */
.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  font-size: .8rem;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.questions-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.redacted-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  animation: zoomIn 4s ease forwards;
  transform-origin: center;
}

/* Question wrapper during the quiz */
.question-wrapper {
  position: absolute;
  top: 10%;
  left: 5%;
  right: 5%;
  color: black;
  font-size: 1.5rem;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%; /* Set max-width to prevent overflow */
  width: 100%; /* Use the available width */
  margin: 0 auto;
  overflow-y: auto; /* Add scrollbar if question content overflows */
  max-height: 80vh; /* Limit the height to 60% of the viewport height */
  box-sizing: border-box; /* Include padding and border in width/height */
}


.answers {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.answer-button {
  background-color: white;
  border: 2px solid black;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.answer-button.correct {
  background-color: green;
  color: white;
}

.answer-button.incorrect {
  background-color: red;
  color: white;
}

.timer {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center; /* Center align the timer text */
}

.sense-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177, 0 0 25px #ff1177, 0 0 30px #ff1177, 0 0 35px #ff1177;
  /* Default glow effect */
}

.sense-animation.positive {
  color: green;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000, 0 0 25px #1177ff, 0 0 30px #1177ff, 0 0 35px #1177ff;
  /* Blue glow effect */
}

.sense-animation.negative {
  color: red;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000, 0 0 25px #ff1177, 0 0 30px #ff1177, 0 0 35px #ff1177;
  /* Red glow effect */
}

/* Timer Expired Animation */
.timer-expired-animation {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: red;
  font-weight: bold;
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000, 0 0 25px #ff1177, 0 0 30px #ff1177, 0 0 35px #ff1177;
}

/* Modal for saving options */
.save-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.save-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-save {
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-save:hover {
  background-color: black;
  color: white;
}

.btn-cancel {
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid red;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel:hover {
  background-color: red;
  color: white;
}

.paperclip-icon {
  position: absolute; /* Position relative to the question-wrapper */
  top: 0.5rem; /* Position inside the white question container */
  right: 0.5rem;
  font-size: 2rem; /* Smaller size for the icon */
  color: #333;
  cursor: pointer;
  z-index: 10; /* Ensure it's above other content in the question-wrapper */
}

.question-number {
  font-size: .8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.results-wrapper {
  position: absolute;
  top: 25%;
  left: 5%;
  right: 5%;
  text-align: center;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
}

.results-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.results-summary {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.results-buttons {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.btn-outline {
  padding: 0.75rem 1.5rem;
  border: 2px solid black;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-outline:hover {
  background-color: black;
  color: white;
}

/* Completed Message Modal */
.completed-message-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000; /* Ensure it’s above other elements */
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 80vw; /* Adjust as needed */
  max-height: 80vh;
  overflow-y: auto; /* Add scroll for overflow */
}

/* Completed Message Modal Content */
.completed-message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.btn-continue {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-continue:hover {
  background-color: black;
  color: white;
}
/* Top Bar */
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  z-index: 100;
  box-sizing: border-box;
}

.account-section {
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
}

.account-icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.info-section {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: white;
  flex-grow: 1;
  justify-content: center; /* Center the info section to avoid cramming */
}

.info-item {
  font-size: 1rem; /* Base size */
  font-weight: normal;
}

.pipe-divider {
  color: #888;
  margin: 0 0.5rem;
}

.paperclip-icon {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  margin-left: 1rem; /* Add space to prevent it from being crammed against other elements */
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0; /* Align to the left of the account section */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0.5rem 0;
  z-index: 200;
  width: 150px;
}

/* Dropdown Item Styling */
.dropdown-item {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: none; /* Remove any default button border */
  text-align: left; /* Align text to the left for a cleaner look */
  background-color: transparent; /* Remove the gray background */
}

/* Hover Effect for Dropdown Items */
.dropdown-item:hover {
  background-color: black; /* Dark background on hover */
  color: white; /* White text on hover */
  border-radius: 4px;
}

/* Responsive Adjustments for Smaller Screens */
@media (max-width: 800px) {
  .top-bar {
    font-size: 0.9rem;
    padding: 0.4rem 1rem;
  }
  .info-item.dossier-name {
    max-width: 200px; /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
  }
  .account-icon {
    font-size: 1.2rem;
    margin-right: 0.3rem;
  }

  .info-item {
    font-size: 0.8rem; /* Slightly smaller on medium-sized screens */
  }
  .pipe-divider {
    margin: 0 0.4rem;
  }

  .paperclip-icon {
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }

  .dropdown-menu {
    right: 0.5rem;
    width: 130px;
  }

  .dropdown-item {
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
  }
}

/* Further Adjustments for Phone Screens */
@media (max-width: 500px) {
  .top-bar {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.3rem 0.8rem;
    font-size: 0.8rem; /* Shrink font size further */
  }

  .info-section {
    margin-top: 0.5rem;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align left on small screens */
  }

  .account-section {
    margin-bottom: 0.5rem;
  }

  .info-item {
    font-size: 0.8rem; /* Shrink font size for better fit */
    margin: 0 0.3rem;
  }

  .paperclip-icon {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}

@media (max-width: 800px) {
  .question-wrapper {
    top: 10%;
    left: 2%;
    right: 2%;
    padding: 0.5rem;
    max-height: 85vh; /* Increase max height to fit more content */
    font-size: 1rem;
  }

  .timer {
    font-size: 1rem;
  }

  .question-number{
    font-size: 1rem; /* Adjust as needed */
    padding: 0.5rem 0; /* Base padding */
    margin: 0; /* Remove any margin if present */
  }
  .question {
    font-size: 1rem; /* Reduce font size */
  }

  .answers {
    margin-top: 0.5rem;
  }

  .questions-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .answer-button {
    padding: 0.3rem;
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }

  .sense-animation {
    font-size: 1.5rem;
  }

  .timer-expired-animation {
    font-size: 2rem;
  }

  .save-modal,
  .completed-message-modal,
  .results-wrapper {
    padding: 1rem;
    max-width: 90vw;
    max-height: 90vh;
  }

  .results-title,
  .results-summary {
    font-size: 1.3rem;
  }

  .btn-outline,
  .btn-save,
  .btn-cancel,
  .btn-continue {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .paperclip-icon {
    font-size: 1.2rem;
    top: 0.3rem;
    right: 0.3rem;
  }
}

/* Modal for saving options */
.save-modal,
.modal.show,
.completed-message-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 9999; /* Ensure it's above all other elements */
}
.modal {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

/* Modal Dialog Fixes */
.modal-dialog {
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
  margin: 0;
  padding: 0;
}

/* Modal Content Specific Styles */
.modal-content {
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border: none;
  background-color: white !important;
  max-height: 95vh; /* Limit height to 85% of viewport */
  overflow-y: auto; /* Add scroll if content overflows */
}

/* Modal Header */
.modal-header {
  background-color: #1f1f1f;
  color: white;
  padding: 1.2rem;
  border-bottom: none;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-title {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Modal Body */
.modal-body {
  padding: 1.5rem;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

/* Modal Footer */
.modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-top: none;
}

/* Buttons inside the modal footer */
.modal-footer .btn {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

@keyframes zoomInEffect {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5); /* Start smaller and transparent */
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.5); /* Increase size and make visible */
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2); /* Zoom out and fade away */
  }
}
</style>
